<template>
    <layout class="box">
        <div class="flex-item">
            <div class="container">
                <!-- 面包屑 -->
                <div class="flex pb5 x-left mt20 container-bread">
                    <div class="color999">您所在的位置：</div>
                    <a-breadcrumb>
                        <a-breadcrumb-item>
                            <router-link class="color999" :to="{path:'/special-ma/index/'+$route.params.id}">首页</router-link>
                        </a-breadcrumb-item>
                        <a-breadcrumb-item>推荐专家</a-breadcrumb-item>
                    </a-breadcrumb>
                </div>
                <!--    推荐专家   -->
                <div class="charming-style flex x-between mt40">
                    <div class="expert-left">
                        <a-spin :spinning="loading" tip="Loading...">
                            <div class="list-box" v-if="academicianList.length">
                                <expert-list :dataList="academicianList" v-show="!loading"></expert-list>
                            </div>
                            <a-empty v-if="!academicianList.length && !loading" />
                        </a-spin>
                        <div class="t-c mt20">
                            <a-pagination v-model="params.page" :hideOnSinglePage="true" show-quick-jumper :pageSize="params.limit" :total="total" @change="getList" @showSizeChange="handleChangeSize" />
                        </div>
                    </div>
                    <div class="expert-right">
                        <layout-right :config="layoutRightConfig"></layout-right>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import layout from '@/components/layout/index-ma';
import layoutRight from '@/components/layout/layout-right';
import expertList from '@/components/expert-list';
import { jsonFormat } from "@/utils/jsonFormat";
import { committee_id } from '@/config';

export default {
    name: "SpecialMaExpertList",
    components: {
        layout, expertList, layoutRight
    },
    data() {
        return {
            academicianList: [],
            loading: true,
            params: {
                limit: 15,
                topic_id: this.$route.params.id, 
                page: 1,
                is_count: true,
                top_committee_id:committee_id
            },
            total:0,
            layoutRightConfig: {
                news: {
                    show: true, // 是否显示
                    methods: 'post',
                    type: 'ma',
                    title: '相关资讯', // 标题
                    moreLink:'/special-ma/infomation-list/'+this.$route.params.id,
                    detailLink:'/special-ma/infomation-detail/'+this.$route.params.id,
                    data: {
                        page: 1,
                        limit: 5, 
                        topic_id: this.$route.params.id, 
                        topic_committee_id: committee_id,
                        
                    },   // 参数条件
                },
                video: {
                    show: true, // 是否显示
                    methods: 'get',
                    type: 'ma',
                    title: '相关视频', // 标题
                    moreLink: '/special-ma/video-list/'+this.$route.params.id,
                    data: {
                        page: 1,
                        PageSize: 4,
                        no_count: false,
                        topic_id: this.$route.params.id, 
                        topic_committee_id: committee_id
                    },   // 参数条件
                },
                guideDownload: {
                    show: true, // 是否显示
                    title: '相关指南', // 标题
                    type: 'ma',
                    methods: 'post',
                    moreLink: '/special-ma/guide-list/'+this.$route.params.id,
                    detailLink:'/special-ma/guide-detail/'+this.$route.params.id,
                    data: {
                        limit: 5, 
                        topic_id: this.$route.params.id, 
                        page: 1,
                        topic_committee_id:committee_id
                    },   // 参数条件
                },
            },
        }
    },
    created() {
        this.getList();
    },
    methods: {
        getList() {
            this.loading = true;

            this.request.post('ExpertsListV3', this.params).then(res => {
                let formatTemplate = {
                    name: 'real_name',
                    subject: 'department',
                    company: 'org_cnname',
                    // introduce: 'resume',
                    user_id: 'user_id',
                }
                let data = res.data.list;
                data.forEach((item,index)=>{
                    item.jump_type = 'special-ma'
                })
                this.total = parseInt(res.data.count);
                this.academicianList = jsonFormat(data,formatTemplate)

                this.loading = false;
            })
        },
        handleChangeSize(current, size) {
            window.scrollTo(0,0);
            this.paginationParams.pageSize = size;
            this.getList();
        }
    }
}
</script>
<style scoped lang="less">
.list-box {
    // min-height: 500px;
}
.charming-style{
    .expert-left{
        width: calc(66.66% - 30px);
        /deep/.component_box{
            .item-box{
                .item{
                    width: calc((100% - 40px) / 3)!important;
                    &:nth-child(4n+4){
                        margin-right: 20px;
                    }
                    &:nth-child(3n+3){
                        margin-right: 0;
                    }
                }
            }
            
        }
    }
    .expert-right{
        width: 33.33%;
    }
}
@media screen and (max-width:768px){
    .container{
        padding: 10px;
    }
    /deep/.unlogin-header,/deep/.login-header{
        right: 30px!important;
    }
    .container-bread{
        display: none;
    }
    .charming-style{
        display: block;
        margin-top: 20px!important;
        .expert-left{
            width: 100%;
        }
        .expert-right{
            width: 100%;
            margin-top: 50px;
        }
    }
}
@media screen and (max-width:550px){
    .charming-style{
        .expert-left{
            /deep/.component_box{
                .item-box{
                    justify-content: space-between;
                    .item{
                        width: calc((100% - 10px) / 2)!important;
                        margin-right: 0!important;
                    }
                }
            }
        }
    }
}
@media screen and (max-width:450px){
    .charming-style{
        .expert-left{
            /deep/.component_box{
                .item-box{
                    justify-content: space-between;
                    .item{
                        width: 100%!important;
                        margin-right: 0!important;
                    }
                }
            }
        }
    }
}
</style>